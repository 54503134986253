<template>
  <div class="routes">
    <div class="routes-banner">
      <div class="routes-banner__box">
        <h1 class="routes-banner__title">Посмотрите наши цены</h1>
        {{ changeTariffPrice }}
        <p class="routes-banner__description">
          У вас есть бесплатные неограниченные обновления и премиум-поддержка для каждого пакета
        </p>
      </div>
      <div class="wrapper">
        <div class="routes-banner__choose-route">
          <button
            v-for="(item, index) of routers"
            class="routes-banner__button"
            :class="{ active: item.isActive, 'list-group-item': true }"
            @click="selectRoutes(item)"
            data-active="active"
            :key="index"
          >
            {{ item.name }}
            <span v-if="item.discount" class="routes-banner__button-discount"
              >-{{ item.discount }}%</span
            >
          </button>
        </div>
        <form class="extend__wrapper-input" :disabled="!!promocode" @submit.prevent="usePromocode">
          <input
            type="text"
            class="input extend__input"
            placeholder="Промокод"
            v-model="entredPromo"
            :disabled="!!promocode"
            required
          />
          <button :disabled="!!promocode" class="button body-button extend__input-button">
            Применить
          </button>
        </form>
      </div>
    </div>
    <div class="main-rates">
      <div class="main-rates__grid">
        <div class="main-rates__column categories main-rates__header-table">
          <h3 class="main-rates__header main-rates__wrapper">Тарифы</h3>
          <div class="main-rates__table">
            <template v-for="(element, index) in tableCategoriesData">
              <div
                :key="index"
                class="main-rates__table-wrapper"
                :class="{
                  extraBlock: typeof element.extra_value === 'number' || element.help_text,
                }"
              >
                <div class="wrapper">
                  <p
                    class="rates-item__dignifieds-item rates-item__dignifieds-title"
                    :class="checkIsSpecial(element.is_new, element.is_coming_soon)"
                  >
                    {{ element.section_name_name_ru }}
                  </p>
                  <CInfoPopup
                    v-if="element?.section_items.length"
                    :list="element?.section_items"
                    class="main-rates__table-info"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="main-rates__main" ref="mainRates">
          <div
            v-for="item in tariffsName"
            v-show="item.title != 'Бесплатный' || item.title != 'free_3_days'"
            class="main-rates__column main-rates__header-table"
            :class="{
              hidden: item.title === 'Бета тест' || item.title === 'free_3_days',
              current: item.level === currentRateLevel,
            }"
            :key="item.id"
          >
            <div class="main-rates__wrapper">
              <p v-if="item.title === 'L'" class="main-rates__popular">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <path
                    d="M4.77447 0.963526C4.92415 0.502871 5.57585 0.50287 5.72553 0.963525L6.59708 3.6459C6.66402 3.85191 6.856 3.99139 7.07261 3.99139H9.89303C10.3774 3.99139 10.5788 4.6112 10.1869 4.8959L7.90516 6.5537C7.72991 6.68102 7.65658 6.9067 7.72352 7.11271L8.59508 9.79508C8.74475 10.2557 8.21751 10.6388 7.82565 10.3541L5.54389 8.69631C5.36865 8.56898 5.13135 8.56898 4.95611 8.69631L2.67435 10.3541C2.28249 10.6388 1.75525 10.2557 1.90492 9.79508L2.77648 7.11271C2.84342 6.9067 2.77009 6.68102 2.59484 6.5537L0.313082 4.8959C-0.0787745 4.6112 0.122613 3.99139 0.606974 3.99139H3.42739C3.644 3.99139 3.83598 3.85191 3.90292 3.6459L4.77447 0.963526Z"
                    fill="#FFBE5B"
                  />
                </svg>
                Выбор пользователей
              </p>
              <div
                :class="{ popular: item.title === 'XXL' }"
                class="rates-header main-rates__header tariff"
              >
                <h2 class="rates-header__title">
                  {{ item.descriptions }}
                </h2>
                <p class="rates-header__desc">
                  <span
                    v-if="item.title === 'S' || item.title === 'free_3_days'"
                    class="rates-header__desc-free"
                    >Бесплатно</span
                  >
                  <span v-else>
                    {{ finallPrice(item) }}
                  </span>
                </p>
                <p class="rates-header__total">
                  Лимиты на
                  {{ item.extraScalePrice * calculateMonth(item) + item.extraPrice }}₽
                </p>
                <button
                  class="rates-header__button btn"
                  :class="{
                    disabled: isBuyButtonDisabled(item),
                  }"
                  :disabled="isBuyButtonDisabled(item)"
                  @click="onCreateOrderClick(item)"
                  :id="item.id"
                >
                  {{
                    !currentRateLevel
                      ? 'Подключить'
                      : item.level === currentRateLevel
                      ? 'Продлить'
                      : 'Повысить'
                  }}
                </button>
              </div>
            </div>
            <div class="main-rates__table">
              <template v-for="(element, key) in item.categories">
                <div
                  v-if="element.is_editable && element.limit_price"
                  :key="key"
                  class="rates-item__dignifieds-item"
                  :class="{ extraBlock: element.extra_value || element.help_text }"
                >
                  <div
                    :class="{
                      'main-rates__table-extra__wrapper': typeof element.extra_value === 'number',
                    }"
                  >
                    <CTariffCalculator
                      @editPrice="updateTariff(item, $event)"
                      @valueSwitched="updateTariff(item, $event)"
                      :element="element"
                      :isPrevClose="isClose(item.categories, element)"
                      :valueDescription="element.valueDescription"
                    />
                    <p
                      v-if="typeof element.extra_value === 'number'"
                      class="rates-item__dignifieds-item-extraBlock"
                    >
                      До {{ element.extra_value }} позиции
                    </p>
                    <p v-else-if="element.help_text" class="rates-item__dignifieds-item-extraBlock">
                      {{ element.help_text }}
                    </p>
                  </div>
                </div>

                <checkMark
                  v-else-if="typeof element.value === 'boolean'"
                  :key="key"
                  class="rates-item__dignifieds-item"
                  :icon="checkBoolean(element.value)"
                />
                <div v-else :key="key">
                  <p
                    class="rates-item__dignifieds-item"
                    :class="{ extraBlock: element.extra_value || element.help_text }"
                  >
                    {{ element.value }}
                    <span
                      v-if="typeof element.extra_value === 'number'"
                      class="rates-item__dignifieds-item-extraBlock"
                    >
                      До {{ element.extra_value }} позиции
                    </span>
                    <span
                      v-else-if="element.help_text"
                      class="rates-item__dignifieds-item-extraBlock"
                    >
                      {{ element.help_text }}
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CModal class="paymentModal" @closeModal="closePayModal" v-model="paymentModal.shown">
      <h3>Оплата услуг для тарифа «{{ paymentModal.title }}»</h3>
      <p class="paymentModal__text">
        На сумму {{ priceForModal }}₽
        <CInfoPopup
          v-if="currentRateLevel === choosedTariff && this.month === 1 && !isLimitsOnly"
          class="paymentModal__modal"
          :list="[
            'Указывается цена за тариф, плюс выбранные лимиты кратно количеству месяцев приобретения тарифа, начиная с этого месяца',
          ]"
        />
      </p>
      <div class="paymentModal__labels">
        <label
          v-if="currentRateLevel === choosedTariff && this.month === 1"
          :disabled="isBuyLimitsForFreeAcc"
          class="paymentModal__agree"
          for="checkbox"
          ><input
            :disabled="
              !Boolean(limitsPriceValue - firstLoadExtraPrice) ||
              isBuyLimitsForFreeAcc ||
              limitsPriceValue == 0
            "
            type="checkbox"
            name=""
            id="checkbox"
            v-model="isLimitsOnly"
          />
          Оплатить только лимиты
        </label>
        <label class="paymentModal__agree" for="checkboxPayment"
          ><input type="checkbox" name="" id="checkboxPayment" v-model="isAgreeWithPayment" />
          <span
            >Я даю согласие на регулярные списания, на обработку персональных данных и принимаю
            условия
            <a href="/docs/policy.pdf" target="_blank" class="link">публичной оферты</a></span
          >
        </label>
      </div>
      <div class="paymentModal-buttonsWrapper">
        <button class="button primary-button" @click="createOrderClick(true)">
          Оплата банковской картой
        </button>
        <button class="button primary-outline-button" @click="createOrderClick(false)">
          Оплата по счету (для юр. лиц)
        </button>
      </div>
    </CModal>
    <CModal class="paymentModal" @closeModal="restoreVaribels" v-model="registrationModal.shown">
      <div class="carusel__box">
        <swiper
          ref="swiper"
          :slides-per-view="1"
          :space-between="2"
          :loop="false"
          :pagination="false"
          :navigation="false"
          :mousewheel="false"
          noSwipingClass="carusel__carusel"
          class="carusel__carusel"
        >
          <swiper-slide class="carusel__slide" v-for="(item, index) of modalList" :key="index">
            <h2 v-if="!isDone">{{ item.title }}</h2>
            <component
              :is="item.component"
              :isDone="isDone"
              v-model="item.value"
              @click="item.error = null"
              @autoAction="autoAction"
              :isPasswordVisible="item.isPasswordVisible"
              :error="item.error"
              class="carusel__slide-component"
            ></component>
          </swiper-slide>
        </swiper>
        <div v-if="!isDone" class="carusel__box-arrows">
          <ButtonLoader
            :isLoading="isButtonLoading"
            class="button carusel__button body-button"
            @click="next()"
            >Далее</ButtonLoader
          >
        </div>
      </div>
    </CModal>
    <SuccessModal v-model="freeTariffACtivedModal" title="Бесплатный тариф успешно применен!" />
    <SuccessModal v-model="promocodeActivatedModal" title="Промокод успешно применён!" />
  </div>
</template>

<script>
import ModalApp from '@/components/ModalApp.vue';
import checkMark from '../components/checkMark.vue';
import CloseSection from '@/components/ClosePage/CloseSection.vue';
import CModal from '@/components/UI/CModal.vue';
import gsap from 'gsap';
import SuccessModal from '@/components/successModal.vue';
import CTariffCalculator from '@/components/Tariffs/CTariffCalculator.vue';
import CInfoPopup from '@/components/UI/CInfoPopup.vue';
import Vue from 'vue';

import { Navigation, Pagination } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import { mapActions, mapGetters } from 'vuex';
import 'swiper/swiper-bundle.css';
import TPhone from '@/modules/tariffs/components/modal/TPhone.vue';
import TCode from '@/modules/tariffs/components/modal/TCode.vue';
import ButtonLoader from '@/components/buttonLoader.vue';
SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    checkMark,
    ModalApp,
    CloseSection,
    CModal,
    SuccessModal,
    CTariffCalculator,
    CInfoPopup,
    Swiper,
    SwiperSlide,
    TPhone,
    TCode,
    ButtonLoader,
  },
  name: 'RatesPage',
  data() {
    return {
      smsCode: '',
      isDone: false,
      isAgreeWithPayment: false,
      isButtonLoading: false,
      modalList: [
        {
          header: 'phone',
          title: 'Введите номер телефона',
          value: '',
          error: '',
          isPasswordVisible: false,
          component: 'TPhone',
          action: async () => {
            const currentElement = this.modalList[this.$refs.swiper.$el.swiper.activeIndex];
            if (currentElement.isPasswordVisible) {
              try {
                this.loginToAcc(currentElement.value);
                this.isDone = true;
              } catch (error) {}
            } else {
              try {
                await this.registrationAcc(currentElement);
                this.$refs.swiper.$el.swiper.slideNext();
              } catch (error) {
                console.log(error.response.data.phone[0].toLowerCase());
                if (
                  error.response.data.phone[0].toLowerCase() ===
                  'пользователь с таким номером телефона уже зарегистрирован.'
                ) {
                  currentElement.isPasswordVisible = true;
                }
              }
            }
          },
        },
        {
          header: 'code',
          title: 'Введите код из смс',
          value: '',
          error: '',
          component: 'TCode',
          action: async () => {
            try {
              this.$refs.swiper.$el.swiper.slideNext();
            } catch (error) {
              console.log(error);
              this.modalList[this.$refs.swiper.$el.swiper.activeIndex].error =
                error.response.data[0];
            }
          },
        },
      ],
      isFramed: null,
      scrollLeftPosition: 0,
      choosedTariff: 0,
      firstLoadExtraPrice: 0,
      scaleArray: [
        'cabinet_qty',
        'dashboard',
        'price_change_analysis',
        'auto_bidder',
        'seo',
        'positions_quantity',
        'internal_analytics',
        'product_management',
        'supply_purchase_planning',
        'financial_reports',
        'financial_analytics',
        'unit_calc',
      ],
      limitPriceList: {},
      fullPriceValue: '',
      limitsPriceValue: '',
      choosedPrice: '',
      isLimitsOnly: false,
      isBuyLimitsForFreeAcc: false,
      freeTariffACtivedModal: false,
      entredPromo: '',
      promocode: '',
      discount: 0,
      currentRateLevel: '',
      paymentModal: {
        shown: false,
        id: null,
        level: null,
        title: null,
      },
      registrationModal: {
        shown: false,
      },
      tariffsName: [],
      tableCategoriesData: [],
      initialPrice: [],
      month: 1,
      excluded_tariffs: [],
      choosedTariffObject: {},
      routers: [
        {
          name: '6 месяцев',
          months: 6,
          discount: 0,
          isActive: false,
        },
        {
          name: '12 месяцев',
          months: 12,
          discount: 15,
          isActive: false,
        },
        {
          name: '24 месяцев',
          months: 24,
          discount: 25,
          isActive: false,
        },
      ],
      promocodeActivatedModal: false,
    };
  },
  methods: {
    ...mapActions([
      'getUserInfo',
      'getPersonalInfo',
      'getRatesInfo',
      'register',
      'confirmReg',
      'login',
      'createOrder',
    ]),
    closePayModal() {
      this.isLimitsOnly = false;
    },
    isClose(parentElement, currentElement) {
      switch (currentElement.section_name) {
        // Логика закрытия изменение статуса у "дочернего" элемента, если "родительский" без статуса "активный"
        case 'financial_reports':
          const prevElement1 = parentElement.find(
            item => item.section_name === 'financial_analytics',
          );
          return prevElement1 && prevElement1.value;
        case 'supply_purchase_planning':
          const prevElement2 = parentElement.find(
            item => item.section_name === 'product_management',
          );
          return prevElement2 && prevElement2.value;

        // Логика закрытия изменение статуса с "активного" для "родительского" элемента, если "дочерний" в статусе "активный"
        case 'financial_analytics':
          const nextElement = parentElement.find(item => item.section_name === 'financial_reports');
          return nextElement && !nextElement.value;
        case 'product_management':
          const nextElement1 = parentElement.find(
            item => item.section_name === 'supply_purchase_planning',
          );
          return nextElement1 && !nextElement1.value;
        default:
          return true;
      }
    },
    restoreVaribels() {
      this.isDone = false;
      this.modalList.find(item => item.header === 'phone').isPasswordVisible = false;
    },
    async loginToAcc(obj) {
      try {
        this.isButtonLoading = true;
        const { data } = await this.login({
          phone: obj.phone.replace(/[^0-9,+]/g, ''),
          password: obj.password,
        });
        localStorage.setItem('token', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        this.createOrderClick(true);
      } catch (error) {
        console.log('error');
        throw error;
      } finally {
        this.isButtonLoading = false;
      }
    },
    async registrationAcc(obj) {
      try {
        this.isButtonLoading = true;
        await this.register({
          phone: obj.value.phone.replace(/[^0-9,+]/g, ''),
        });
      } catch (error) {
        obj.isPasswordVisible = true;
        throw error;
      } finally {
        this.isButtonLoading = false;
      }
    },
    async autoAction() {
      this.isButtonLoading = true;
      try {
        const response = await this.confirmReg({
          phone: this.modalList
            .find(item => item.header === 'phone')
            .value.phone.replace(/[^0-9,+]/g, ''),
          code: this.modalList[this.$refs.swiper.$el.swiper.activeIndex].value,
        });
        localStorage.setItem('token', response.access);
        localStorage.setItem('refreshToken', response.refresh);
        const token = localStorage.getItem('token');
        Vue.prototype.$http.defaults.headers.common['Authorization'] = `JWT ${token}`;
        this.createOrderClick(true);
      } catch (error) {
        console.log(error);
      } finally {
        this.isButtonLoading = false;
      }
    },
    next() {
      const activeIndex = this.$refs.swiper.$el.swiper.activeIndex;
      this.modalList[activeIndex].action();
    },
    prev() {
      this.$refs.swiper.$el.swiper.slidePrev();
    },
    iframeEvent(event) {
      if (event.origin !== 'http://localhost:8082' || event.origin !== 'https://mamod.ai/') return;
    },
    calculateMonthsDifference(targetDate) {
      const currentDate = new Date();
      const targetDateObject = new Date(targetDate);

      const monthsDifference =
        (targetDateObject.getFullYear() - currentDate.getFullYear()) * 12 +
        targetDateObject.getMonth() -
        currentDate.getMonth();

      return monthsDifference;
    },
    calculateFirstLoadExtraPrice() {
      const findedEl = this.tariffsName.find(el => el.level === this.currentRateLevel);
      this.firstLoadExtraPrice = findedEl.extraScalePrice + findedEl.extraPrice;
    },
    finallPrice(item) {
      const onlyPrice = item?.prices[this.month] - (item?.prices[this.month] * this.discount) / 100;
      return item?.prices[this.month] !== null
        ? (
            onlyPrice +
            item?.extraPrice +
            item?.extraScalePrice * this.calculateMonth(item)
          ).toFixed() + '₽'
        : '-';
    },
    onlyLimitPrice(item) {
      if (this.currentRateLevel !== item.level && this.tableCategoriesData > 0) {
        const diffMonth =
          this.calculateMonthsDifference(this.personalInfo?.current_tariff?.end_date) === 0
            ? 1
            : this.calculateMonthsDifference(this.personalInfo?.current_tariff?.end_date);
        const result = item.categories.reduce(
          (acc, val) => {
            if (this.scaleArray.includes(val.section_name)) {
              if (val.newValue && val.newValue !== val.value) {
                acc.scale += (val.newValue - val.value) * val.limit_price;
              }
            } else {
              if (val.newValue && val.newValue !== val.value) {
                acc.notScale += (val.newValue - val.value) * val.limit_price;
              }
            }
            return acc;
          },
          { scale: 0, notScale: 0 },
        );
        this.limitsPriceValue = (result.notScale + result.scale * diffMonth).toFixed();
      } else {
        this.limitsPriceValue = (
          item?.extraPrice +
          item?.extraScalePrice * this.calculateMonth(item)
        ).toFixed();
      }
    },
    onlyFullPrice(item) {
      this.fullPriceValue = (
        item?.prices[this.month] -
        (item?.prices[this.month] * this.discount) / 100
      ).toFixed();
    },
    // Функция для вычисления цены
    calculatePrice(val, currentElement) {
      const { newValue, section_name, value, type, firstValue, initialValue, limit_price } = val;
      const price =
        type === 'boolean' && firstValue !== value
          ? limit_price
          : newValue
          ? (value - initialValue) * limit_price + (newValue - value) * limit_price
          : (value - initialValue) * limit_price;
      console.log('price', price);
      return price;
    },

    updateTariff(item, editedValue) {
      const { newValue, section_name } = editedValue;

      const index = this.tariffsName.findIndex(el => el.id === item.id);
      if (index === -1) return;

      const tariff = this.tariffsName[index];
      const foundCategory = tariff.categories.find(
        category => category.section_name === section_name,
      );

      if (!foundCategory) return;

      // Обновление значений категории
      if (foundCategory.type === 'boolean') {
        foundCategory.value = newValue;
      }
      foundCategory.newValue = newValue;

      // Вычисление цен
      const { categories } = tariff;
      const result = categories.reduce(
        (acc, val) => {
          const price = this.calculatePrice(val, item);
          const key = this.scaleArray.includes(val.section_name) ? 'scale' : 'notScale';
          acc[key] += price;
          return acc;
        },
        { scale: 0, notScale: 0 },
      );

      // Обновление цен в объекте тарифа за лимиты
      this.tariffsName[index].extraScalePrice = result.scale;
      this.tariffsName[index].extraPrice = result.notScale;
    },

    getPrice(item, editedValue) {
      let tariffPrice = item.prices[this.month];
      if (editedValue?.newValue) tariffPrice += editedValue.newValue * editedValue.limit_price;
      if (this.excluded_tariffs?.length && this.excluded_tariffs?.includes(item.id)) {
        return tariffPrice / this.month;
      }
      return tariffPrice ? (tariffPrice / this.month) * (1 - this.discount / 100) : '-';
    },

    usePromocode() {
      const data = {
        promocode: this.entredPromo,
        period: this.month,
      };
      this.$store
        .dispatch('usePromocode', data)
        .then(response => {
          !this.promocode ? (this.promocode = this.entredPromo) : this.promocode;
          if (response?.discount) this.discount = response.discount;
          if (response?.excluded_tariffs) this.excluded_tariffs = response.excluded_tariffs;

          this.entredPromo = '';
          this.promocode = '';
          this.promocodeActivatedModal = true;
        })
        .catch(error => {
          this.entredPromo = '';
          console.log(error);
        });
    },
    async getRates() {
      let current_tariff = '';
      if (!this.isFramed) {
        const response = await this.getPersonalInfo();
        current_tariff = response.current_tariff;
      }
      const response = await this.getRatesInfo();
      const descriptionObject = {
        cabinet_qty: 'API',
        positions_quantity: 'SKU',
        price_change_analysis: 'SKU',
        dashboard: 'SKU',
      };
      this.tariffsName = response;
      this.tariffsName[3].categories.forEach(item => {
        this.limitPriceList = {
          ...this.limitPriceList,
          [item.section_name]: item.limit_price,
        };
      });
      this.tariffsName.forEach(item => {
        this.$set(item, 'price', item.prices[1]);
        this.$set(item, 'extraPrice', 0);
        this.$set(item, 'extraScalePrice', 0);
        item.categories.forEach(el => {
          el.firstValue = el.value;
          el.initialValue = el.value;
          if (item.id === current_tariff?.tariff?.id && current_tariff[el.section_name]) {
            if (typeof current_tariff[el.section_name] === 'boolean') {
              el.value = current_tariff[el.section_name];
              el.initialValue = el.value;
              item.extraScalePrice += el.limit_price;
              if (el.value) el.is_editable = false;
            } else {
              el.value = current_tariff[el.section_name];
              if (el.value - el.initialValue > 0) {
                if (this.scaleArray.includes(el.section_name)) {
                  item.extraScalePrice += (el.value - el.initialValue) * el.limit_price;
                } else {
                  item.extraPrice += (el.value - el.initialValue) * el.limit_price;
                }
              }
            }
            this.calculateFirstLoadExtraPrice();
          }
          if (descriptionObject[el.section_name]) {
            el.valueDescription = descriptionObject[el.section_name];
          }
        });
      });

      this.tableCategoriesData = response[0].categories;
    },
    async getInfo() {
      const { data } = await this.getUserInfo();
      if (!data && !data?.current_tariff) return;
      console.log('data', data);
      this.currentRateLevel = data?.current_tariff?.tariff?.level;
    },
    async sendMessage() {
      const linkForPay = await this.getOreder();
      window.parent.postMessage(linkForPay, '*');
    },
    onCreateOrderClick(item) {
      this.choosedTariffObject = item;
      if (this.isFramed) {
        if (localStorage.getItem('token')) {
          this.createOrderClick(true);
        } else {
          this.registrationModal.shown = true;
        }
      } else {
        const { id, level, descriptions } = item;
        this.choosedTariff = level;
        this.choosedPrice = this.finallPrice(item);
        this.onlyLimitPrice(item);
        this.onlyFullPrice(item);
        this.paymentModal = { shown: true, id, level, title: descriptions };
        this.isBuyLimitsForFreeAcc = false;
      }
    },
    async createOrderClick(paymentTypeIsCard) {
      try {
        const diffLimits = this.findDiffLimits();
        const data = {
          isProlongate: (() => {
            if (this.currentRateLevel === 1) return false;
            return this.currentRateLevel === this.paymentModal.level && !this.isLimitsOnly
              ? true
              : false;
          })(),
          transmitData: {
            tariff_id: this.paymentModal.id || this.choosedTariffObject.id,
            period: this.month,
            tariff_pay: !this.isLimitsOnly,
            is_subscriptable: this.isAgreeWithPayment,
            ...diffLimits,
          },
        };

        try {
          await this.createOrder(data);
        } catch (error) {
          console.log(error);
          throw error;
        }
        if (this.promocode && !this.excluded_tariffs.includes(this.paymentModal.id)) {
          const promocodeData = {
            promocode: this.entredPromo,
            period: this.month,
          };
          await this.$store.dispatch('usePromocode', promocodeData);
        }
        if (this.paymentModal.title === 'S') {
          if (this.currentRateLevel === 1) {
            this.sendOrder(paymentTypeIsCard);
          } else {
            this.freeTariffACtivedModal = true;
            await this.getPersonalInfo();
            await this.getRates();
          }
        } else {
          if (this.isFramed) {
            this.sendMessage();
          } else {
            this.sendOrder(paymentTypeIsCard);
          }
        }
      } catch (error) {
        console.error('Произошла ошибка при создании заказа:', error);
      }
    },

    onCreateOrderClickFreeTariff(item) {
      const { id, level, title, descriptions } = item;
      this.paymentModal = { id, level, title: descriptions };
      if (level === this.currentRateLevel) {
        this.choosedPrice = this.finallPrice(item);
        this.$set(this.paymentModal, 'shown', true);
        this.isBuyLimitsForFreeAcc = this.isLimitsOnly = true;
      }
    },
    findDiffLimits() {
      const currentTariff = this.tariffsName.filter(
        item => item.id === this.choosedTariffObject.id,
      )[0];
      const filtredTariff = currentTariff.categories.filter(item => item.is_editable);
      const objectArray = {};
      filtredTariff.forEach(item => {
        if (typeof item.newValue === 'number') {
          objectArray[item.section_name] =
            this.currentRateLevel === this.paymentModal.level
              ? item.newValue - item.value
              : item.newValue - item.value;
        } else if (typeof item.newValue === 'boolean') {
          objectArray[item.section_name] = item.newValue;
        }
      });
      return objectArray;
    },
    async getOreder() {
      try {
        const res = await this.$store.dispatch('sendOrder', {
          count_months: this.month,
        });
        return res.data;
      } catch (error) {
        console.error('Ошибка при получении заказа:', error);
        throw error;
      }
    },
    async sendOrder(paymentTypeIsCard) {
      const response = await this.getOreder();
      if (!paymentTypeIsCard) {
        this.$router.push('/checking-account');
        return;
      }
      window.location.href = response.url;
    },
    checkBoolean(data) {
      return data ? 'prime' : 'err';
    },
    checkIsSpecial(isNew, isComming) {
      if (isNew) {
        return 'new';
      } else if (isComming) {
        return 'coming';
      } else {
        return;
      }
    },

    selectRoutes(item) {
      this.month = item.months;
      this.routers = this.routers.map(el => {
        el.isActive = el === item ? true : false;
        return el;
      });
      this.tariffsName.forEach(el => {
        const tariffPrice = el.prices[this.month];
        const asv = tariffPrice / this.month;
        gsap.to(el, { duration: 0.5, price: asv });
      });
    },
    animateScrollBack() {
      const container = this.$refs.mainRates;
      const targetScroll = 0; // Начальное положение
      this.scrollWithAnimation(container, targetScroll);
    },
    scrollWithAnimation(element, targetScroll, onComplete) {
      const startScroll = element.scrollLeft;
      const duration = 1000; // Настройте длительность анимации в миллисекундах
      const startTime = performance.now();

      const animateScroll = currentTime => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const c1 = 1.4;
        const c2 = -2.6;
        const c3 = 1.3;
        const c4 = 0.1;
        // const c1 = 1.4;
        // const c2 = -2.6;
        // const c3 = 1.3;
        // const c4 = 0;

        const easedProgress =
          c1 * Math.pow(progress, 3) + c2 * Math.pow(progress, 2) + c3 * progress + c4;

        const newScroll = startScroll + (targetScroll - startScroll) * easedProgress;
        element.scrollLeft = newScroll;

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        } else {
          // Вызывается после завершения анимации
          if (onComplete) onComplete();
        }
      };

      requestAnimationFrame(animateScroll);
    },
    recalculateExtraPrice() {
      this.tariffsName.forEach(item => {
        if (item.level === this.currentRateLevel) {
          console.log('item', item);
        }
      });
    },
    calculateMonth(obj) {
      if (obj.level === this.currentRateLevel) {
        return this.month + 1;
      }
      return this.month;
    },
  },
  watch: {
    month() {
      console.log('months');
      // this.calculateFirstLoadExtraPrice();
      this.recalculateExtraPrice();
    },
  },
  computed: {
    ...mapGetters(['personalInfo']),
    isBuyButtonDisabled() {
      return item => {
        // -3 === бесплатный
        if (this.currentRateLevel == -3 || !this.currentRateLevel) {
          return false;
        } else if (
          this.currentRateLevel > item.level ||
          (this.currentRateLevel == -10 && this.currentRateLevel < item.level)
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    priceForModal() {
      if (this.isLimitsOnly) {
        const diffMonth = this.calculateMonthsDifference(
          this.personalInfo?.current_tariff?.end_date,
        );
        const findedTariff = this.tariffsName.find(item => item.level === this.choosedTariff);

        const result = findedTariff.categories.reduce(
          (acc, val) => {
            if (this.scaleArray.includes(val.section_name)) {
              if (val.newValue && (val.newValue !== val.value || val.type === 'boolean')) {
                if (val.type === 'boolean') {
                  acc.scale += val.limit_price;
                } else {
                  acc.scale += (val.newValue - val.value) * val.limit_price;
                }
              }
            } else {
              if (val.newValue && val.newValue !== val.value) {
                acc.notScale += (val.newValue - val.value) * val.limit_price;
              }
            }
            return acc;
          },
          { scale: 0, notScale: 0 },
        );
        return (result.notScale + result.scale * diffMonth).toFixed();
      } else {
        return Number(this.fullPriceValue) + Number(this.limitsPriceValue);
      }
    },
    changeTariffPrice(item, value) {
      this.tariffsName.forEach(item => {
        let fixedNumb = 0;
        this.discount ? (fixedNumb = 2) : (fixedNumb = 0);

        item.price = (item?.price - (item?.price * this.discount) / 100).toFixed(fixedNumb);
      });
    },

    changeTariff() {
      const activeRouteMonths = this.routers.find(route => route.isActive).months;

      const newTariff = {};

      for (let key in tariff) {
        newTariff[key] = tariff[key].map(val =>
          Number.isInteger(val) ? val * activeRouteMonths : val,
        );
      }

      return newTariff;
    },
  },
  async mounted() {
    try {
      this.isFramed =
        window != window.top || document != top.document || self.location != top.location;
    } catch (e) {
      this.isFramed = true;
    }
    if (this.isFramed) {
      window.addEventListener('message', this.iframeEvent, false);
      await this.getInfo();
      await this.getRates();
      return;
    }
    await this.getInfo();
    await this.getRates();

    const container = this.$refs.mainRates;
    const targetScroll = container.scrollLeft + 200;
    this.scrollWithAnimation(container, targetScroll, () => {
      this.animateScrollBack();
    });
  },
};
</script>

<style lang="scss" scoped>
.carusel {
  &__box {
    &-arrows {
      margin-top: 5px;
    }
  }
  &__slide {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.hide {
  opacity: 0;
}
.price {
  display: flex;
  &__small {
    display: flex;
    justify-content: flex-end;
    min-width: 35px;
  }
}
.color-prime {
  color: #3586ff;
}
.customSwal {
  position: fixed;
  top: 30px;
  width: 25vw;
  right: 0;
  transform: translateX(100%);
  transition: transform 1s ease;
  background: #ffa7a7;
  z-index: 9998;
  padding: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  display: flex;
  align-items: baseline;
}
.customSwal.active {
  right: -10px;
  transform: translateX(0%);
}
.customSwal__icon svg {
  height: 40px;
}
.customSwal__icon path {
  fill: #e36767;
}
.customSwal__text {
  margin-left: 15px;
  font-size: 1.2em;
  padding-right: 5vw;
  color: #fff;
}
.toogle__label {
  display: flex;
  align-items: center;
}
.toggle__input {
  display: none;
}
.custom-switcher-checkbox {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 2.6em;
  height: 1.4em;
  border-radius: 1.5em;
  background: #c0ccdc;
  cursor: pointer;
  transition: all 200ms ease;
}
.custom-switcher-checkbox::before {
  content: '';
  position: absolute;
  top: 0.15em;
  left: 0.15em;
  width: 1.1em;
  height: 1.1em;
  background-color: white;
  border-radius: 50%;
  transition: all 200ms ease;
}
.toggle__input:checked ~ .custom-switcher-checkbox::before {
  left: calc(2.6em - 1.4em + 0.15em);
}
.toggle__input:checked ~ .custom-switcher-checkbox {
  background: #3586ff;
}
@media (max-width: 1100px) {
  .customSwal {
    width: 40vw;
  }
}
@media (max-width: 700px) {
  .customSwal {
    width: 65vw;
  }
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^='top'] .tooltip-arrow::before {
  border-top-color: #aaa; /* цвет стрелочки (по умолчанию #000)*/
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^='right'] .tooltip-arrow::before {
  border-right-color: #aaa; /* цвет стрелочки (по умолчанию #000)*/
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .tooltip-arrow::before {
  border-bottom-color: #aaa; /* цвет стрелочки (по умолчанию #000) */
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^='left'] .tooltip-arrow::before {
  border-left-color: #aaa; /* цвет стрелочки (по умолчанию #000) */
}
.tooltip-inner {
  max-width: 25vw; /* максимальная ширина подсказки (по умолчанию 200px) */
  color: #666; /* цвет шрифта */
  background: #fff; /* цвет фона (по умолчанию #000) */
  border: 1px solid #d7d7d7;
  box-shadow: 0 0px 15px -7px rgb(0 0 0);
  border-radius: 15px; /* радиус скругдения углов */
}
.tooltip.show {
  opacity: 1 !important;
}
@media (max-width: 600px) {
  .tooltip-inner {
    max-width: 45vw; /* максимальная ширина подсказки (по умолчанию 200px) */
  }
}

.alert.alert-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav.nav-pills .nav-link {
  background-color: transparent;
}
.alert-success > label {
  font-size: 0.8rem;
}
.alert.alert-warning.d-flex.align-items-center.p-2.my-3 {
  font-size: 0.85em;
}
.customize-col {
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 800px) and (max-width: 1200px) {
  .customize-col {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: 10px;
  }
}
tr td {
  padding: 0px 0px 3px 20px;
}
.card-body.text-lg-start.text-center.pt-0 {
  display: flex;
  flex-direction: column;
}
table {
  flex: 1 0 100%;
}
span.irs-bar.irs-bar--single {
  background-color: #8126c6;
}
span.irs-handle.single {
  border-color: #8126c6;
}
span.irs-single {
  background-color: #8126c6 !important;
}

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  border-top-color: #8126c6 !important;
}
.flex-95 {
  flex: 0 0 95%;
}
.flex-95 p {
  display: none;
}
.prices {
  display: flex;
  justify-content: center;
  flex: 1 0 400px;
  align-items: flex-end;
  margin-bottom: 20px;
  font-weight: 700;
}
.result__price {
  justify-content: flex-start;
  display: block;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.2em;
}
.text-right {
  text-align: right;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.quantity-arrow-minus,
.quantity-arrow-plus {
  margin: 0 8px;
  border-radius: 50%;
  width: 32px;
  height: 30px;
  border: 1px solid #8126c6;
  background-color: #fff !important;
  box-shadow: 0px 0px 13px -5px #8126c6;
  color: #9f9f9f;
  transition: all 0.6s ease;
}
.quantity-arrow-minus:hover,
.quantity-arrow-plus:hover {
  box-shadow: 0px 0px 18px -3px #8126c6;
}
.readonly {
  cursor: default;
}
h4 {
  margin-bottom: 0;
}
.form-control {
  padding: 3px 5px;
  width: 100%;
  outline: none;
}
@media (max-width: 625px) {
  h4 {
    font-size: calc(1rem + 0.3vw);
    line-height: 1;
  }
  .price,
  .result__price {
    font-size: 1.2em;
  }
}
@media (max-width: 425px) {
  .result .btn {
    padding: 10px 15px;
    font-size: 0.7em;
  }
  .calculate .card-body {
    padding: 0.5rem;
  }
  .result .flex-95 {
    flex: 0 0 50%;
  }
}
span.irs.irs--round {
  margin-top: -15px;
  margin-bottom: 10px;
}
.plus-minus {
  display: grid;
  width: 95%;
}
.pinned-bottom {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.pinned-bottom:last-child {
  border-right: none;
}
.pinned-bottom h4 {
  flex: 1;
  font-size: 1em;
}
.pinned-bottom input {
  margin-top: 5px;
  flex: 0 0 26%;
}
.content-footer {
  justify-content: space-between;
  align-items: center;
}
.content-footer a,
.content-footer p {
  display: block;
  margin: 5px 10px;
}
.content-footer p {
  text-align: right;
}
footer {
  position: relative;
  margin-top: 25px;
}
.footer__logo {
  position: relative;
  left: calc(100% - 200px);
  margin-bottom: 25px;
}
@media (max-width: 1070px) {
  .content-footer {
    flex-direction: column;
  }
  .footer__links {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .footer__info {
    margin-bottom: 25px;
  }
  .footer__logo {
    position: relative;
    left: calc(50% - 100px);
    margin-bottom: 0;
  }
  .content-footer p {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .card table {
    font-size: 0.9em;
  }
}
.unactive {
  filter: brightness(0.9);
}
.unactive a {
  filter: contrast(0.5);
  transform: scale(1) !important;
  cursor: no-drop;
}

/* new styles */

.pricing-banner {
  max-width: 420px;
}

.pricing-banner__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  margin: 0;
  padding: 0;
  color: #3a416f !important;
}

.pricing-banner__desc {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  margin: 15px 0 25px;
  padding: 0;
  color: #3a416f !important;
  opacity: 0.6;
}

.nav.choose-rate .nav-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.4px;
  color: #3a416f;
  padding: 12px 40px;
}
.choose-rate .nav-item {
  border-right: 1px solid rgba(58, 65, 111, 0.2);
}

.choose-rate .nav-item:nth-child(4) {
  border-right: none;
}
.choose-rate .nav-link.active {
  background: #fff;
  border-radius: 8px;
}

.main-rates {
  background: #fff;
  border-radius: 8px;
  &__wrapper {
    height: 174px;
    display: flex;
    flex-direction: column;
  }
  &__headers {
    display: flex;
  }
  &__table {
    &-wrapper {
      border-bottom: 1px solid rgba(227, 226, 238, 0.6);
      border-right: 1px solid rgba(227, 226, 238, 0.6);
      height: 35px;

      &.extraBlock {
        height: 70px;
        display: flex;
        align-items: center;
        .wrapper {
          width: 100%;
        }
        .rates-item__dignifieds-title {
          border-bottom: none;
        }
      }

      .rates-item__dignifieds-item {
        border-right: none;
      }
    }
    &-info {
      margin-right: 5px;
    }
    &-extra {
      &__wrapper {
        max-width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    color: #3a416f;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(227, 226, 238, 0.6);
    border-right: 1px solid rgba(227, 226, 238, 0.6);
    &-table {
      &.current {
        border: 2px solid #334fe080;
      }
    }
  }
  &__column {
    background: #fff;
    width: 100%;
    min-width: 190px;
    &.categories {
      max-width: 270px;
    }
  }
  &__grid {
    display: flex;
  }
  .rates-subtitle {
    min-height: 29px;
  }
  &__main {
    width: 100%;
    display: flex;
    transition: transform 1s ease-in-out;
    white-space: nowrap;

    .rates-item__dignifieds-item {
      justify-content: center;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    & {
      -ms-overflow-style: none;
    }
    & {
      overflow: -moz-scrollbars-none;
    }
  }
  &__grid {
    row-gap: 1px;
    &.no-free {
      grid-template-columns: minmax(268px, 1fr) repeat(4, minmax(172px, 0.5fr));
    }
  }
  &__popular {
    border-radius: 0px 0px 12px 12px;
    background: #334fe0;
    color: #fff;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 116.667% */
    letter-spacing: -0.24px;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 70%;
    margin: 0 auto;
    padding: 5px 0;
  }
}

.rates {
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    height: 100%;
    color: #3a416f;
    gap: 10px;
    margin-bottom: 0;

    &__title {
      color: #3a416f;
      text-align: center;
      font-family: Manrope;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px; /* 100% */
      letter-spacing: -0.6px;
      white-space: pre-wrap;
    }
    &__desc {
      color: #513cd3;
      text-align: center;
      font-family: Manrope;
      font-size: 20px;
      font-weight: 600;
      line-height: 18px; /* 90% */
      letter-spacing: -0.4px;
      &-free {
        color: #31c363;
      }
    }
    &__total {
      color: rgba(58, 65, 111, 0.5);
      text-align: center;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 100% */
      letter-spacing: -0.28px;
    }
    &__desc {
      &.disabled {
        opacity: 0.3;
      }
      &.accent {
        color: #3586ff;
      }
    }
    &__button {
      padding: 9px 20px;
      background: rgba(81, 60, 211, 0.2);
      border-radius: 6px;
      box-shadow: none;
      margin-bottom: 0;
      color: #513cd3;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px; /* 118.182% */
      letter-spacing: -0.22px;
      &.disabled {
        display: flex;
        align-items: center;
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
    &.popular {
      height: 150px;
    }
  }
  &-item {
    &__dignifieds-item {
      letter-spacing: -0.02em;
      color: #3a416f;
      padding: 8px;
      box-sizing: border-box;
      margin-bottom: 0;
      background: #fff;
      border-bottom: 1px solid rgba(227, 226, 238, 0.6);
      height: 35px;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      &.extraBlock {
        height: 70px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 13px;
        line-height: 13px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #3a416f;
        padding: 8px;
        box-sizing: border-box;
        margin-bottom: 0;
        background: #fff;
        margin-left: 8px;
      }
      &-extraBlock {
        flex-direction: column;
        justify-content: space-between;
        font-size: 9px;
        line-height: 13px;
        font-weight: 400;
        letter-spacing: -0.02em;
        color: #3a416f;
        padding: 8px 0;
        box-sizing: border-box;
        margin-bottom: 0;
        background: #fff;
        text-align: center;
      }
    }
    &__dignifieds-title {
      font-style: normal;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: -0.02em;
      position: relative;
      color: #3a416f;
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__dignifieds-main-title {
      grid-row: 3 / 10;
    }
    &__dignifieds-main-title.marketing {
      grid-row: 11 / 14;
    }
    &__dignifieds-main-title.seo {
      grid-row: 15 / 19;
    }
  }
  &-subtitle {
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;

    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    border-bottom: 1px solid rgba(227, 226, 238, 0.6);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(121, 127, 168, 0.4);
  }
}

.rates-item__dignifieds-item,
.rates-item__dignifieds-title,
.rates-subtitle {
  padding-left: 15px;
}

.rates-item__dignifieds-item.new::after {
  content: 'NEW';
  background: #3586ff;
  border-radius: 2px;

  font-weight: 600;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px;
  margin-left: 10px;
}

.rates-item__dignifieds-item.coming::after {
  content: 'СКОРО';
  background: #6d5adf;
  border-radius: 2px;

  font-weight: 600;
  font-size: 9px;
  line-height: 9px;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px;
  margin-left: 10px;
}

.rates-item__dignifieds.free.disabled p {
  opacity: 0.3;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(36, 36, 36, 0.8);
  display: none;
}

.mpodal-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 537px;
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  text-align: center;
  z-index: 10001;
  padding: 25px 50px 50px 50px;
}

.modal-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #3a416f;
  margin: 0 0 10px;
}
.modal-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: #67748e;
  margin-bottom: 20px;
}
.modal-carrent-date {
  color: #dd3383;
}

.modal-btn {
  background: linear-gradient(180deg, #3586ff 0%, #cb317a 100%);
  box-shadow: 11px 17px 33px -8px rgba(216, 50, 129, 0.4);
  border-radius: 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #fff7fb;
  max-width: 437px;
  box-sizing: border-box;
  border: none;
  padding: 20px 145px;
}

span.irs-bar.irs-bar--single {
  border: 2px solid #3586ff;
}
span.irs-handle.single {
  background: #3586ff;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
}
.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #3586ff !important;
}
.irs--round .irs-handle {
  width: 18px !important;
  height: 18px !important;
  top: 29px !important;
}

.calculate {
  margin-top: 20px;
}
.calculate__box {
  margin-right: 11px;
  margin-bottom: 20px;
  flex: 1 1 300px;
}

.calculate__body {
  padding: 0 !important;
}
.calculate-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  border-bottom: 1px solid #efefef;
  margin-bottom: 11px;
}
.calculate-header__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.calculate-header__description {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3a416f;
  opacity: 0.6;
}
.calculate__form {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
}
.calculate__input-wrapper {
  width: 100%;
  border-bottom: 1px solid #efefef;
  margin-bottom: 11px;
}
.calculate-container {
  width: 90%;
  margin: 0 auto;
}
.calculate-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calculate__input-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
}

.calculate__input-input {
  background: #f4f7fb;
  border: 1px solid #e0e7ef;
  border-radius: 6px;
  max-width: 70px;
  padding: 7px 16px;
  text-align: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3586ff;
}
.calculate__input-input:focus {
  border-color: #e293d3;
  outline: 0;
  box-shadow: 0 0 0 2px #e9aede;
}
.calculate__input-range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #dee4ec;
  margin: 0.6em 0;
}

input[type='range'] {
  color: #3586ff;
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: #dee4ec;
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}
/* === range commons === */
input[type='range'] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

/* === WebKit specific styles === */
input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  position: relative;
}

input[type='range']::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50%
    calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}
input[type='range']::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100%
    calc(var(--track-height) + 1px);
}

.calculate__input-box {
  align-items: center;
  justify-content: space-between;
}
.pinned-wrapper {
  margin-top: 20px;
}
.pinned-wrapper,
.toggle-wrapper {
  display: flex;
  align-items: center;
}
.pinned__title {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
}

.pinned__counter {
  background: #f4f7fb;
  border: 1px solid #e0e7ef;
  border-radius: 8px;
  align-items: center;
  max-width: 160px;
  justify-content: space-between;
  margin-top: 10px;
}

.pinned__button {
  background: #f4f7fb;
  border: 1px solid #e0e7ef;
  border-radius: 8px;
  color: #3a416f;
  box-shadow: none;
}
.pinned__button:hover {
  box-shadow: none;
}
.pinned-bottom {
  padding: 0;
  margin-right: 40px;
}
.pinned__input {
  margin: 0 !important;
  text-align: center;
  background: transparent !important;
  border: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.pinned__input:focus {
  border: none;
  box-shadow: none;
}
.pinned__button {
  margin: 0 !important;
  background: #f4f7fb !important;
  border: 1px solid #e0e7ef;
  border-radius: 8px;
  width: 40px;
  height: 40px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.toggle-wrapper {
  margin-top: 36px;
}
.toggle__switch {
  display: flex;
  align-items: center;
}
.toggle__switch label {
  margin: 0 !important;
}

.toggle__switch:first-child {
  margin-right: 40px;
}
.toggle__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
  margin-left: 10px;
}
.calculation__total-wrapper {
  flex: 1 0 400px;
}
.calculation__total {
  background: #f4f7fb;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
}

.calculation__total-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
  text-align: left;
  padding: 0;
}
.calculation__total .price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.calculation__total .price span,
.calculation__total .price small,
.result__price small {
  margin-left: 6px;
}
.calculation__total tr {
  border-bottom: 1px solid #efefef;
  height: 46px;
}
.result__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.result__price-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.result__box .result__price {
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: #3a416f;
}
.calculatePayButton {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #fff7fb;
  padding: 20px 0;
  background: linear-gradient(180deg, #3586ff 0%, #cb317a 100%);
  box-shadow: 11px 17px 33px -8px rgba(216, 50, 129, 0.4);
  border-radius: 8px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
.calculation__total {
  display: flex;
  flex-direction: column;
}
.calculate .prices {
  align-items: flex-start;
  flex: 1 0 auto;
}
.calculate {
  &__notification {
    color: #dd3383;
    text-align: right;
  }
}
.calculation {
  &__total {
    &-wrapper {
      flex: 1 1 0;
    }
  }
}

.extend {
  width: 62%;
  &__head {
    max-width: 505px;
    margin-bottom: 20px;
  }
  &__title {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #3a416f;
  }
  &__text {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.4px;
    color: rgba(58, 65, 111, 0.6);
  }
  &__wrapper-input {
    position: relative;
  }
  &__input {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    color: #3a416f;
    background: #fff;
    padding: 12px 130px 12px 15px;
    &-button {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(0, -50%);
    }
  }
  &__box {
    background: #f5f6f8;
    border-radius: 16px;
    padding: 35px 30px 30px 30px;
    margin-top: 30px;
  }
  &__wrapper-column {
    align-items: normal;
  }
  &__head-row {
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__headname {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #3a416f;
    opacity: 0.3;
  }
  &__old-price {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-decoration-line: line-through;
    text-decoration-thickness: 0.1px;
    color: #3a416f;
    opacity: 0.5;
  }
  &__price {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #3a416f;
  }
  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
  &__name {
    &-rate {
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.4px;
      color: #3a416f;
    }
  }
}
.routes {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &-banner {
    margin-top: 20px;
    border-radius: 8px;
    width: 100%;
    background-image: url('@/assets/img/banner.png'),
      linear-gradient(90deg, #fff 55.18%, rgba(51, 110, 224, 0.6) 264.88%);
    background-repeat: no-repeat;
    background-position: top right;
    padding: 30px;
    padding-bottom: 28px;
    box-sizing: border-box;
    &__box {
      max-width: 420px;
      margin-bottom: 20px;
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: #3a416f;
    }
    &__description {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: #3a416f;
      opacity: 0.6;
      margin-top: 15px;
    }

    &__choose-route {
      background: rgba(227, 226, 238, 0.6);
      backdrop-filter: blur(50px);
      border-radius: 8px;
      display: flex;
      width: fit-content;
      padding: 4px;
    }

    &__button {
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -0.4px;
      color: #3a416f;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 42px;
      margin: 0 2px;
      border-left: 1px solid rgba(58, 65, 111, 0.2);
      transition: all 0.3s;
      &.active {
        background: #fff;
        border-radius: 8px;
        padding: 12px 40px;
        box-shadow: 0px 1px 1px #d4cede, 0px 0px 10px rgba(201, 205, 210, 0.2);
      }
      &:last-child {
        border-right: none;
      }
      &:first-child {
        border-left: none;
      }
      &-discount {
        color: #fff;
        text-align: center;
        font-family: Manrope;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px; /* 100% */
        letter-spacing: -0.4px;
        border-radius: 6px;
        background: #3586ff;
        padding: 5px;
      }
    }
  }
}

@media (max-width: 492px) {
  .routes-banner {
    background-image: none;
    padding: 19px 12px;

    .wrapper {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
    &__choose-route {
      max-width: 100%;
      width: 100%;
      overflow-x: auto;
      flex-direction: column;
    }
    &__title {
      font-size: 18px;
    }
    &__description {
      font-size: 12px;
    }
    &__button {
      border: none;
    }
  }
  .extend {
    &__wrapper {
      &-input {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.v-menu__content {
  z-index: 104 !important;
}

.paymentModal {
  .modalBox {
    max-width: 420px;

    h3 {
      font-size: 18px;
      text-align: center;
    }
  }
  &__labels {
    margin-top: 20px;
  }
  &__text {
    text-align: center;
    font-size: 15px;
    margin: 10px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
  }
  &__modal {
    display: flex;
    align-self: center;
    .popup__box {
      right: 0;
      left: unset;
    }
  }
  &__agree {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    font-size: 12px;
    input {
      margin-top: 3px;
    }
  }
  &-buttonsWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .button {
      width: 100%;
      padding: 20px 40px;
    }
  }
}
</style>
